<template>
  <div>
    <div class="top">
      <img src="@/assets/image/photo.png" />
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/about' }"
          >关于我们</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/common' }"
          >常见问题</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/project' }"
          >项目中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/download' }"
          >app下载</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/contact' }"
          >联系我们</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div>
      <img class="banner" src="@/assets/photo/hexacosa.png" />
    </div>
    <img class="picture" src="@/assets/photo/thirty.png" alt="" />
    <p>分享到:</p>
    <div class="button">
      <!-- <el-row>
        <el-button type="success">微信</el-button>
        <el-button type="primary">QQ好友</el-button>
        <el-button type="danger">新浪微博</el-button>
        <el-button type="warning">腾讯微博</el-button>
        <el-button type="info">人人网</el-button>
      </el-row> -->
      <img src="@/assets/photo/31.png" />
      <img src="@/assets/photo/32.png" />
      <img src="@/assets/photo/33.png" />
      <img src="@/assets/photo/34.png" />
      <img src="@/assets/photo/35.png" />
      <img src="@/assets/photo/36.png" />
    </div>
    <div class="bottom">
      <HelloWorld></HelloWorld>
    </div>
  </div>
</template>
<script>
import HelloWorld from '../components/HelloWorld.vue'
export default {
  data() {
    return {}
  },
  methods: {},
  components: {
    HelloWorld,
  },
}
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}
.top {
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
}
.top img {
  width: 300px;
  height: 50px;
  margin-left: 50px;
}
.picture {
  width: 70%;
  height: 400px;
  padding: 0 130px;
  margin-top: 50px;
}
p {
  margin-left: 300px;
  margin-top: 30px;
}
.button {
  padding: 70px 480px;
  margin: -100px;
}
.bottom {
  margin: 50px;
}
img {
  padding: 5px;
}
</style>
