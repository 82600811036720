<template>
  <div class="about">
    <div class="top">
      <img src="@/assets/image/photo.png" />
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/about' }"
          >关于我们</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/common' }"
          >常见问题</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/project' }"
          >项目中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/download' }"
          >app下载</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/contact' }"
          >联系我们</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div>
      <img class="banner" src="@/assets/photo/fourteen.png" />
    </div>
    <p>项目中心</p>
    <div class="advantageInfos">
      <div class="addvantageInfo" v-for="(item, index) in list" :key="index">
        <img :src="item.img" alt="" />
        <span>{{ item.text }}</span>
      </div>
    </div>
    <HelloWorld></HelloWorld>
  </div>
</template>
<script>
import HelloWorld from '../components/HelloWorld.vue'
export default {
  data() {
    return {
      list: [
        {
          img: require('@/assets/photo/fifteen.png'),
          text: '同城/跨城顺路送',
        },
        {
          img: require('@/assets/photo/sixteen.png'),
          text: '同城/跨城顺路车',
        },
        {
          img: require('@/assets/photo/seventeen.png'),
          text: '万能生活服务',
        },
        {
          img: require('@/assets/photo/nineteen.png'),
          text: '积分商城随意换',
        },
        {
          img: require('@/assets/photo/twenty.png'),
          text: '附近红包抢不停',
        },
        {
          img: require('@/assets/photo/tricosa.png'),
          text: '更多资料增加中',
        },
      ],
    }
  },
  methods: {},
  components: {
    HelloWorld,
  },
}
</script>
<style scope>
.banner {
  width: 100%;
  height: 350px;
}
.top {
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
}
.top img {
  width: 300px;
  height: 50px;
  margin-left: 50px;
}
p {
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #aaa;
}
.advantageInfos {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 80px 50px 100px;
  justify-content: space-between;
}
.addvantageInfo {
  width: 30%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}
.addvantageInfo img {
  width: 100%;
}
.addvantageInfo span {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
}
</style>
