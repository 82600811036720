<template>
  <div>
    <div class="top">
      <img src="@/assets/image/photo.png" />
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/about' }"
          >关于我们</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/common' }"
          >常见问题</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/project' }"
          >项目中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/download' }"
          >app下载</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/contact' }"
          >联系我们</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div>
      <img class="banner" src="@/assets/photo/hexacosa.png" />
    </div>
    <img class="picture" src="@/assets/photo/homeImg001.png" alt="" />
    <div class="center">
      <p>
        369玖玖帮小程序是“”西安倡导者网络科技有限公司”筹划3年研发出来的全新模式的商业综合性平台。沿用服务思维下的商业模式，便捷的过程创造共享经济。
      </p>
      <p>
        369玖玖帮小程序努力打造全新模式大众创业O2O共享经济生活平台”的企业愿景，去实现“数据产生价值、流量创造未来、创新
        合作 共赢
        发展”的核心价值观。369玖玖帮平台”以及369玖玖帮APP，都是在基于人、车、物的交互式场景，围绕不同场景下，满足消费者个性化需求，旨在构建一个“万众轻资产创业、全民共享服务”的新型业态体系下融合价值的平台。
      </p>
      <p>
        如果您对369玖玖帮小程序还有什么疑问。可以联系西安倡导者网络科技有限公司。
      </p>
    </div>
    <p>分享到:</p>
    <div class="button">
      <img src="@/assets/photo/31.png" />
      <img src="@/assets/photo/32.png" />
      <img src="@/assets/photo/33.png" />
      <img src="@/assets/photo/34.png" />
      <img src="@/assets/photo/35.png" />
      <img src="@/assets/photo/36.png" />
    </div>
    <div class="bottom">
      <HelloWorld></HelloWorld>
    </div>
  </div>
</template>
<script>
import HelloWorld from '../components/HelloWorld.vue'
export default {
  data() {
    return {}
  },
  methods: {},
  components: {
    HelloWorld,
  },
}
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}
.top {
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
}
.top img {
  width: 300px;
  height: 50px;
  margin-left: 50px;
}

.picture {
  width: 50%;
  height: 400px;
  padding: 0 300px;
  margin-top: 50px;
}
p {
  margin-left: 300px;
  margin-top: 15px;
}
.button {
  margin-left: 350px;
  padding: 30px;
  margin-top: -55px;
}

.center {
  margin-right: 500px;
  font-size: 12px;
}
</style>
