<template>
  <div class="about">
    <div class="top">
      <img src="@/assets/image/photo.png" />
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/about' }"
          >关于我们</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/common' }"
          >常见问题</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/project' }"
          >项目中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/download' }"
          >app下载</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/contact' }"
          >联系我们</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="banner">
      <img src="@/assets/photo/homeImg002.png" />
    </div>
    <p class="title">我们是谁</p>
    <p>
      西安倡导者网络科技有限公司，于2017年在陕西省西安市成立，专注于打造移动互联网时代的“社交电商平台+共享经济”模式。我们秉承“打造中国首家大众创业O2O共享经济生活平台”的企业愿景，去实现“数据产生价值、流量创造未来、创新
      合作 共赢
      发展”的核心价值观，公司一直以“引领产业新思路，推动企业新发展”为使命。目前公司全力打造的“369玖玖帮”APP平台，在基于人、车、物的交互式场景，围绕不同场景下，满足消费者个性化需求，旨在构建一个“万众轻资产创业、全民共享服务”的新型业态体系下融合价值的平台。
    </p>
    <p class="one">企业愿景：打造中国首家大众创业O2O共享经济生活平台</p>
    <p class="two">企业使命：引领产业新思路 推动企业新发展</p>
    <p class="two">
      核心价值观：数据产生价值、流量创造未来、创新、合作、共赢、发展！
    </p>
    <div class="content">
      <div v-for="(item, index) in list" :key="index" class="contentInfo">
        <img :src="item.img" alt="" />
        <div class="contentTxte">
          <span>{{ item.text }}</span>
          <span>{{ item.text1 }}</span>
        </div>
      </div>
    </div>
    <div class="advantage">
      <p class="title">玖玖帮优势</p>
      <p class="three">全民创业-万众创新-共享经济</p>
      <div class="advantageInfos">
        <div
          class="addvantageInfo"
          v-for="(item, index) in advantageList"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <div class="team">
      <p class="title">我们的团队</p>
      <p class="three">
        西安倡导者网络科技有限公司，于2017年在陕西省西安市成立，专注于打造移动互联网时代的“社交电商平台+共享经济”模式。让用户通过369玖玖帮平台发挥个人空余时间的最大价值，并发掘多渠道、多维度的商机…
      </p>
      <div class="teamInfos">
        <div class="teamInfo" v-for="(item, index) in teamList" :key="index">
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
    <HelloWorld></HelloWorld>
  </div>
</template>

<script>
import HelloWorld from '../components/HelloWorld.vue'
export default {
  name: 'about',
  data() {
    return {
      list: [
        {
          img: require('@/assets/photo/circular.png'),
          text: '我们沉浸于用户体验',
          text1: '双重身份随意切换轻松创业',
        },
        {
          img: require('@/assets/photo/diamond.png'),
          text: '服务思维下的商业模式',
          text1: '便捷的过程创造共享经济',
        },
        {
          img: require('@/assets/photo/picture.png'),
          text: '精益合作和分享的态度',
          text1: '让我们鼎力相助你的梦想',
        },
      ],
      advantageList: [
        {
          img: require('@/assets/photo/green.png'),
          text: '我们秉承“大众创业O2O共享经济生活平台”的企业愿景，去实现“数据产生价值、流量创造未来、合作共赢”的核心价值观。引领产业新思路，推动企业新发展，为使命。',
        },
        {
          img: require('@/assets/photo/bule.png'),
          text: '我们秉承“大众创业O2O共享经济生活平台”的企业愿景，去实现“数据产生价值、流量创造未来、合作共赢”的核心价值观。引领产业新思路，推动企业新发展，为使命。',
        },
        {
          img: require('@/assets/photo/anat.png'),
          text: '我们秉承“大众创业O2O共享经济生活平台”的企业愿景，去实现“数据产生价值、流量创造未来、合作共赢”的核心价值观。引领产业新思路，推动企业新发展，为使命。',
        },
      ],
      teamList: [
        {
          img: require('@/assets/photo/one.png'),
        },
        {
          img: require('@/assets/photo/two.png'),
        },
        {
          img: require('@/assets/photo/five.png'),
        },
        {
          img: require('@/assets/photo/three.png'),
        },
        {
          img: require('@/assets/photo/four.png'),
        },
      ],
    }
  },
  methods: {
    clickAbout() {
      console.log(1234)
      this.$router.push('../About.vue')
    },
  },
  components: {
    HelloWorld,
  },
}
</script>
<style scope>
.banner {
  width: 100%;
  height: 350px;
  background-color: pink;
}
.top {
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
}
.top img {
  width: 300px;
  height: 50px;
  margin-left: 50px;
}
p {
  color: #000;
  padding: 0 180px;
  font-size: 14px;
}
.title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 50px;
}
.one {
  padding: 10px 0 5px;
  margin-top: 10px;
  margin: 0 180px;
  border-bottom: 1px solid #aaa;
}
.two {
  margin-top: 30px;
  padding: 30px 0 5px;
  margin: 0 180px;
  border-bottom: 1px solid #aaa;
}
.content {
  padding: 80px 180px 50px;
  display: flex;
  justify-content: space-between;
}

.team {
  padding: 80px 180px 50px;
}
.team p {
  padding: 0;
}
.team p {
  padding: 0;
  margin: 0;
}
.contentTxte {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.contentInfo img {
  width: 300px;
  height: 200px;
}
.contentTxte span {
  font-size: 20px;
}
.advantage {
  padding: 20px 100px;
  background-color: #dfdfdf;
}
.three {
  text-align: center;
  margin-top: -40px;
}
.advantageInfos {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
}
.addvantageInfo {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
}
.addvantageInfo img {
  width: 300px;
  height: 200px;
}
.addvantageInfo span {
  font-size: 12px;
  margin: 20px;
}
.teamInfos {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* padding: 30px 100px; */
}
.teamInfo {
  width: 32%;
  height: 200px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.teamInfo:nth-child(3) {
  width: 32% !important;
  height: 405px;
}
.teamInfo:nth-child(4),
.teamInfo:nth-child(5) {
  width: 32%;
  height: 200px;
  margin-top: -205px;
}
img {
  width: 100%;
  height: 100%;
}
</style>
