<template>
  <div class="about">
    <div class="top">
      <img src="@/assets/image/photo.png" />
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/about' }"
          >关于我们</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/common' }"
          >常见问题</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/project' }"
          >项目中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/download' }"
          >app下载</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/contact' }"
          >联系我们</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div>
      <img class="banner" src="@/assets/photo/Start.png" />
    </div>
    <div class="content">
      <div class="left">
        <div class="one">
          <div class="box">
            <span class="text">常见问题</span>
            <span>News</span>
          </div>
          <div>
            <p class="written">公司新闻</p>
            <p class="written">项目动态</p>
          </div>
        </div>
        <div class="two">
          <div class="box">
            <span class="text">联系方式</span>
            <span>Contact</span>
          </div>
          <img class="picture" src="@/assets/photo/Map.png" alt="" />
          <div class="second">
            <p>地 址：陕西省西安市凤城三路东段369号</p>
            <p>公司名称：西安倡导者网络科技有限公司</p>
            <p>售前咨询：400-8857-369</p>
            <p>售后技术：400-8857-369</p>
            <p>咨询Q Q：564814435</p>
            <p>邮 箱：cdzwlkj@xecn.net</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="center">
          <image src=""></image>
          <span>您现在的位置：首页 ></span>
        </div>
        <div class="boxer">
          <div class="boxerInfos" @click="goTo()">
            <div class="boxerInfo" v-for="(item, index) in list" :key="index">
              <img :src="item.img" alt="" />
              <div class="boxerInfo1">
                <p class="title">{{ item.title }}</p>
                <p class="text">{{ item.text }}</p>
                <p class="more">more>></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HelloWorld></HelloWorld>
  </div>
</template>
<script>
import HelloWorld from '../components/HelloWorld.vue'
export default {
  data() {
    return {
      list: [
        {
          img: require('@/assets/photo/homeImg001.png'),
          title: '369玖玖帮天使APP怎么样',
          text: '369玖玖帮天使APP是“”西安倡导者网络科技有限公司”筹划3年研发出来的全新模式的商业综合性平台。沿用服务思维下的商业模......',
        },
        {
          img: require('@/assets/photo/seven.png'),
          title: '西安倡导者介绍369玖玖帮天使APP',
          text: '西安倡导者介绍369玖玖帮天使APP的功能还是比较多的。但是有些人可能对此还不是特别了解，今天我就一起跟西安倡导者简单的了解......',
        },
        {
          img: require('@/assets/photo/homeImg001.png'),
          title: '369玖玖帮天使APP有哪些特点',
          text: '369玖玖帮天使APP是一种同城生活服务app，这个app可以进行网约车，不仅如此还可以在上面叫跑腿和线上商城，帮助大家解决......',
        },
        {
          img: require('@/assets/photo/seven.png'),
          title: '369玖玖帮天使APP怎么使用',
          text: '369玖玖帮天使APP是一种同城生活服务app，这个app可以进行网约车，不仅如此还可以在上面叫跑腿和线上商城，帮助大家解决......',
        },
        {
          img: require('@/assets/photo/homeImg001.png'),
          title: '西安369玖玖帮天使APP主要是做什么',
          text: '西安369玖玖帮天使APP主要是做什么的，可能很多人都不是很明白，现在市场上的APP是非常多的，在不明白某个APP是做什么的，很多......',
        },
        {
          img: require('@/assets/photo/seven.png'),
          title: '369玖玖帮天使APP的特色',
          text: '369玖玖帮天使APP是一种同城生活服务app，这个app可以进行网约车，不仅如此还可以在上面叫跑腿和线上商城，帮助大家解......',
        },
        {
          img: require('@/assets/photo/homeImg001.png'),
          title: '西安倡导者带你简直经济共享',
          text: '2021年12月4日下午，369玖玖帮团队核心成员，应邀来到由西安市经济技术开发区区县代理郭云霞郭总组织的以“共享机遇·......',
        },
        {
          img: require('@/assets/photo/seven.png'),
          title: '369玖玖帮天使APP都有哪些优势？',
          text: '目前公司全力打造的“369玖玖帮”APP平台，在基于人、车、物的交互式场景，围绕不同场景下，满足消费者个性化需求，旨在构......',
        },
      ],
    }
  },
  methods: {
    goTo() {
      this.$router.push('../Applet.vue')
    },
  },
  components: {
    HelloWorld,
  },
}
</script>
<style scope>
.banner {
  width: 100%;
  height: 300px;
}
.content {
  display: flex;
  padding: 0 100px;
  justify-content: space-between;
}
.top {
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
}
.top img {
  width: 300px;
  height: 50px;
  margin-left: 50px;
}
.left {
  width: 300px;
  height: 500px;
  margin-top: 30px;
}
.one {
  height: 200px;
  background-color: #fff8fb;
}
.box {
  height: 30px;
  padding: 10px;
  margin-top: 30px;
  background-color: #281ec0;
}
.box span {
  color: #fff;
  font-size: 20px;
}
.text {
  padding: 0 10px;
  font-weight: bold;
  color: #fff;
}
.written {
  height: 20px;
  padding: 15px;
  font-weight: bold;
  border-bottom: 1px solid #aaa;
}
.picture {
  width: 300px;
  height: 100px;
}
.second {
  font-size: 17px;
  padding: 10px;
  background-color: #fff8fb;
  /* border-left: 1px solid #ddd;
  border-right: 1px solid #ddd; */
}
.right {
  width: 1000px;
  margin-top: 30px;
  margin-left: 50px;
}
.center {
  font-size: 20px;
  border-bottom: 1px solid #aaa;
  padding: 15px;
  color: #aaa;
}

.boxerInfo {
  display: flex;
  margin: 20px;
}
.boxerInfo img {
  width: 300px;
  height: 150px;
}
.boxerInfo .title {
  color: #281ec0;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ccc;
}
.boxerInfo .title:hover {
  color: #333;
}
.boxerInfo1 {
  padding: 10px 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.boxerInfo1 .text {
  padding-top: 6px;
  font-size: 12px;
  color: #000;
  font-weight: normal;
}
.more {
  font-size: 15px;
  color: #ccc;
  padding: 10px;
}
</style>
