<template>
  <div class="about">
    <div class="top">
      <img src="@/assets/image/photo.png" />
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/about' }"
          >关于我们</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/common' }"
          >常见问题</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/project' }"
          >项目中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/download' }"
          >app下载</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/contact' }"
          >联系我们</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div>
      <img class="banner" src="@/assets/photo/service.png" />
    </div>
    <div class="advantageInfos">
      <div class="addvantageInfo" v-for="(item, index) in list" :key="index">
        <img :src="item.img" alt="" />
        <span>{{ item.text }}</span>
        <span class="text">{{ item.title }}</span>
      </div>
    </div>
    <img class="center" src="@/assets/photo/about.png" />
    <!-- 高德地图 -->
    <div class="amap-page-container">
      <div :style="{ width: '100%', height: '300px' }">
        <el-amap vid="amap" :plugin="plugin" class="amap-demo" :center="center">
        </el-amap>
      </div>

      <div class="toolbar">
        <span v-if="loaded"> location: lng = {{ lng }} lat = {{ lat }} </span>
        <span v-else></span>
      </div>
      <div v-on:click="req_post()"></div>
    </div>
    <div class="nint">
      <p>
        陕西省西安市浐灞生态区欧亚大道3639号丝路国际创意梦工场一期1号楼B座3层
      </p>
      <p>Mobile: cdzwlkj@xecn.net</p>
    </div>
    <HelloWorld></HelloWorld>
  </div>
</template>
<script>
import HelloWorld from '../components/HelloWorld.vue'
export default {
  data() {
    const self = this
    return {
      list: [
        {
          img: require('@/assets/photo/ten.png'),
          text: '给我们打电话',
          title: '400-8857-369',
        },
        {
          img: require('@/assets/photo/eleven.png'),
          text: 'QQ在线联系',
          title: '400-8857-369',
        },
        {
          img: require('@/assets/photo/twelve.png'),
          text: '给我们打电话',
          title: 'cdzwlkj@xenct',
        },
      ],
      //高德地图
      center: [121.59996, 31.197646],
      lng: 0,
      lat: 0,
      loaded: false,
      plugin: [
        {
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 100, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: 'RB', //定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions: 'all',
          pName: 'Geolocation',
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                console.log(result)
                if (result && result.position) {
                  self.lng = result.position.lng
                  self.lat = result.position.lat
                  self.center = [self.lng, self.lat]
                  self.loaded = true
                  self.$nextTick()
                }
              })
            },
          },
        },
      ],
    }
  },
  req_post() {
    const that = this
    const registerUrl =
      'http://restapi.amap.com/v3/batch?key=0f83a6507af9dc6433a242d3c2ca954f'
    const newUserInfo = {
      ops: [
        {
          url:
            '/v3/place/around?offset=10&page=1&key=0f83a6507af9dc6433a242d3c2ca954f' +
            that.lng +
            ',' +
            that.lat +
            '&output=json&radius=100000&types=080000',
        },
      ],
    }
    that.axios
      .post(registerUrl, newUserInfo, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(function (response) {
        console.log(response['data'][0]['body']['pois'])
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  methods: {},
  components: {
    HelloWorld,
  },
}
</script>
<style scope>
.banner {
  width: 100%;
  height: 300px;
}
.top {
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
}
.top img {
  width: 300px;
  height: 50px;
  margin-left: 50px;
}
.advantageInfos {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 100px 20px 100px;
  justify-content: space-between;
}
.addvantageInfo {
  width: 400px;
  height: 300px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  border-left: #ccc 1px solid;
}
.addvantageInfo img {
  padding: 50px 150px;
  width: 100px;
  height: 100px;
}
.addvantageInfo span {
  font-size: 15px;
  margin-top: 5px;
  text-align: center;
}
.addvantageInfo .text {
  font-size: 25px;
}
.bottom {
  width: 100%;
  height: 300px;
}
.center {
  width: 100%;
}
.amap-demo {
  height: 300px;
}
.nint {
  width: 100%;
  height: 50px;
  padding: 30px;
  text-align: center;
}
</style>
